import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"

// @material-ui/icons
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import ChevronRight from "@material-ui/icons/ChevronRight"
import ErrorIcon from "@material-ui/icons/Error"

// core components
import Button from "components/CustomButtons/Button.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"

// page styles
import formCompleteStyle from "assets/jss/material-kit-pro-react/views/hotPepperEatingRegistrationPageSections/hotPepperEatingFormCompleteStyle.jsx"
const useStyles = makeStyles(formCompleteStyle)

export default function HotPepperEatingFormComplete(props) {
  const { handleNext, submitError } = props

  const handleClick = () => {
    handleNext()
  }

  const classes = useStyles()

  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer justifyContent="center">
          <GridItem xs={12} md={10} lg={8}>
            {!submitError ? (
              <>
                <h2 className={classes.title}>Registration Complete</h2>
                <CheckCircleIcon className={classes.paymentSuccessIcon} />
                <h3 className={classes.paymentTitle}>Thank You!</h3>
                <p>
                  <b>Your Registration is Complete</b>
                  <br />
                  You will be recieving a follow-up confirmation email shortly.
                  If you do not recieve this email, reach out to the cook-off
                  email below.
                </p>
                <p>
                  <b>Questions?</b> <br />
                </p>
                <ul>
                  <li>
                    Contact:{" "}
                    <a href="mailto:cookoff@chilimania.com">
                      cookoff@chilimania.com
                    </a>{" "}
                  </li>
                </ul>
              </>
            ) : (
              <>
                <h2 className={classes.title}>Registration Failed</h2>
                <ErrorIcon className={classes.paymentErrorIcon} />
                <h3 className={classes.paymentTitle}>We're Sorry.</h3>
                <p>
                  <b>
                    We were unable to collect your registration information.
                  </b>
                  <br />
                </p>
                <p>
                  <b>Your Payment Was Accepted</b>
                </p>
                <p>
                  We're sorry, we are unable to submit your registration form.
                  To futher discuss registration details please use the contact
                  email listed below.
                </p>
                <ul>
                  <li>
                    Contact:{" "}
                    <a href="mailto:cookoff@chilimania.com">
                      cookoff@chilimania.com
                    </a>{" "}
                  </li>
                </ul>
              </>
            )}
            <GridContainer justifyContent="center">
              <GridItem xs={12} className={classes.submitButton}>
                <Link to="/scoville-showdown">
                  <Button color="primary" onClick={handleClick}>
                    Return to Website
                    <ChevronRight />
                  </Button>
                </Link>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  )
}

HotPepperEatingFormComplete.propTypes = {
  handleNext: PropTypes.func,
  submitError: PropTypes.bool,
}
